import { useLocation } from 'react-router-dom';
import useAuth from './UseAuth';
import StateContainer from './components/StateContainer';
import RoutesSite from './Routes';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function App() {
  const history = useLocation();
  useAuth(history);

  // useEffect(() => {
  //   const dati = {
  //     descrizione:
  //       "Quelli sotto elencati sono i dati dell'utente che ti sta per scrivere. Tienili a mente quando devi rispondergli",
  //     nome: 'Mario Rossi',
  //     eta: 30,
  //     professione: 'Impiegato',
  //     hobby: 'Sport',
  //   };

  //   // Usa la funzione globale
  //   window.tailorgpt.setContext(dati);
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      <Navigate to="/login" />;
    }
  });
  return (
    <StateContainer>
      <div className="App">
        <RoutesSite />
      </div>
    </StateContainer>
  );
}

export default App;
