import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  ReactFlow,
  useNodesState,
  Background,
  useEdgesState,
  addEdge,
  Controls,
  MarkerType,
} from '@xyflow/react';
import { FaStar as Star } from 'react-icons/fa6';
import '@xyflow/react/dist/style.css';
import StartNode from './KnotsEditor/StartNode';
import ImpostazioniDiBase from './KnotsEditor/tabmenu/ImpostazioniDiBase';
import InputUtenteWidget from './KnotsEditor/tabmenu/InputUtenteWidget';
import GestioneVariabili from './KnotsEditor/tabmenu/GestioneVariabili';
import ApiEsterne from './KnotsEditor/tabmenu/ApiEsterne';
import GenerazioneOutput from './KnotsEditor/tabmenu/GenerazioneOutput';
import { StateContext } from './StateContainer';
import ModalSaveConversation from './KnotsEditor/modal/ModalSaveConversation';
import ModalLiveVersion from './KnotsEditor/modal/ModalLiveVersion';
import { LuFileJson as JsonIcon } from 'react-icons/lu';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalJsonEditor from './KnotsEditor/modal/ModalJsonEditor';
import EndNode from './KnotsEditor/EndNode';
import ExecutionNode from './KnotsEditor/ExecutionNode';

const nodeTypes = {
  start: StartNode,
  end: StartNode,
  execution: StartNode,
};

const snapGrid = [20, 20];
const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const modalTabMenu = [
  {
    id: 1,
    title: 'Base',
    section: '#impostazioniBase',
  },
  {
    id: 2,
    title: 'Gestione variabili',
    section: '#gestioneVariabili',
  },
  {
    id: 3,
    title: 'Input utente',
    section: '#inputUtente',
  },
  {
    id: 4,
    title: 'API esterne',
    section: '#apiEsterne',
  },
  {
    id: 5,
    title: 'Generazione output',
    section: '#generazioneOutput',
  },
];

const KnotsEditor = ({ editorValueString }) => {
  const [viewport, setViewport] = useState(defaultViewport);

  const onViewportChange = useCallback((viewport) => {
    setViewport(viewport);
  }, []);

  const { id } = useParams();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [currentConversationalVersion, setCurrentConversationalVersion] =
    useState('');
  const [versioniAgenteConversazionale, setVersioniAgenteConversazionale] =
    useState([]);

  const {
    selectedNode,
    setSelectedNode,
    token,
    setError,
    dettagliNodi,
    setDettagliNodi,
    setShowModalVariables,
    currentTab,
    setCurrentTab,
  } = useContext(StateContext);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
      },
    })
      .then((response) => {
        let editorValueArray = JSON.parse(
          response.data.response.conversationalJSON
        );

        if (editorValueArray.viewport) {
          console.log('sono io', editorValueArray.viewport);
          console.log('sono altro', defaultViewport);
          setViewport(editorValueArray.viewport);
        }

        console.log(editorValueArray);

        if (editorValueArray) {
          const newNodes = Object.keys(editorValueArray)
            .filter(
              (key) => key !== 'fullfillment_variables' && key !== 'viewport'
            )
            .map((key) => {
              const nodeData = editorValueArray[key];
              return {
                id: nodeData.id.toString(),
                type: nodeData.type,
                data: { label: nodeData.node_label, actions: [] },
                position: nodeData.position,
                sourcePosition: 'right',
                targetPosition: 'left',
              };
            });

          setNodes(newNodes);

          const listFullfillmentVariables =
            editorValueArray.fullfillment_variables || [];

          const fullfillmentVariables = listFullfillmentVariables.filter(
            (fv) =>
              fv.label === '' &&
              fv.nome_variabile === '' &&
              fv.istruzioni_estrazione === ''
          );

          const newDettagliNodi = Object.keys(editorValueArray).reduce(
            (acc, key) => {
              if (key !== 'fullfillment_variables' && key !== 'viewport') {
                const nodeData = editorValueArray[key];
                acc[key] = {
                  node_label: nodeData.node_label,
                  id: nodeData.id,
                  type: 'text',
                  sourcePosition: 'right',
                  targetPosition: 'left',
                  position: nodeData.position,
                  intents: nodeData.intents || [],
                  fullfillment:
                    fullfillmentVariables.filter(
                      (fv) => fv.nodeId === nodeData.id
                    ) || [],
                };
              }
              return acc;
            },
            {}
          );

          setDettagliNodi(
            JSON.parse(response.data.response.conversationalJSON)
          );

          setVersioniAgenteConversazionale(
            response.data.response.versionsConversational
          );

          const liveVersion =
            response.data.response.versionsConversational.find(
              (version) => version.isLive
            );
          if (liveVersion) {
            setCurrentConversationalVersion(liveVersion.id);
          }

          const newEdges = [];
          Object.keys(newDettagliNodi).forEach((key) => {
            const node = newDettagliNodi[key];
            if (node.intents) {
              node.intents.forEach((intent) => {
                newEdges.push({
                  id: `${node.id}-${intent.nextNode}`,
                  type: 'bezier',
                  source: node.id.toString(),
                  target: intent.nextNode.toString(),
                  animated: true,
                  type: 'bezier',
                  markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                  },
                  data: {
                    trigger: intent.trigger,
                    output_intent: intent.output_intent,
                    type: 'action',
                    id: intent.id,
                    target: intent.nextNode,
                    source: node.id,
                  },
                });
              });
            }
          });

          setEdges(newEdges);
        } else {
          const initialNode = {
            id: '1',
            type: 'start',
            data: { label: 'Nodo 1', actions: [] },
            position: { x: 0, y: 50 },
            sourcePosition: 'right',
          };

          setNodes([initialNode]);
          setDettagliNodi({
            'nodo-1': {
              node_label: 'Nodo 1',
              id: 1,
              position: { x: 0, y: 50 },
              sourcePosition: 'right',
            },
          });
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setError(err);
      });
  }, [id, token, setError]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge({ ...params, animated: true }, eds));

      const sourceNodeId = params.source;
      const targetNodeId = params.target;

      setDettagliNodi((prev) => {
        const updatedDetails = { ...prev };

        if (!updatedDetails[`nodo-${sourceNodeId}`].intents) {
          updatedDetails[`nodo-${sourceNodeId}`].intents = [];
        }

        const intentExists = updatedDetails[
          `nodo-${sourceNodeId}`
        ].intents.some((intent) => intent.nextNode === targetNodeId);

        if (!intentExists) {
          updatedDetails[`nodo-${sourceNodeId}`].intents.push({
            trigger: '',
            output_intent: '',
            nextNode: targetNodeId,
            id: `${sourceNodeId}-${targetNodeId}`,
            source: sourceNodeId,
            target: targetNodeId,
            animated: true,
            type: 'bezier',
            type: 'action',
          });
        }

        return updatedDetails;
      });
    },
    [setEdges, setDettagliNodi]
  );

  const handleAddNode = () => {
    const newNodeId = nodes.length + 1;
    const nodeKey = `nodo-${newNodeId}`;

    // Calcolare la posizione al centro del viewport corrente
    // Convertiamo dalle coordinate dello schermo alle coordinate del flow
    const centerX = -viewport.x + window.innerWidth / 2 / viewport.zoom;
    const centerY = -viewport.y + window.innerHeight / 2 / viewport.zoom;

    const newNode = {
      id: newNodeId.toString(),
      type: nodes.length === 0 ? 'start' : 'execution', // Se è il primo nodo, type = "start"
      data: { label: `Nodo ${newNodeId}`, actions: [] },
      position: { x: centerX, y: centerY },
      sourcePosition: 'right',
      targetPosition: 'left',
    };

    setNodes((nds) => [...nds, newNode]);

    setDettagliNodi((prev) => {
      const updatedDetails = {
        ...prev,
        [nodeKey]: {
          type: nodes.length === 0 ? 'start' : 'execution',
          node_label: `Nodo ${newNodeId}`,
          id: newNodeId,
          position: { x: centerX, y: centerY },
          sourcePosition: 'right',
          targetPosition: 'left',
          intents: [],
        },
      };

      if (selectedNode) {
        const sourceNodeId = selectedNode.id;
        const sourceNodeKey = `nodo-${sourceNodeId}`;

        if (!updatedDetails[sourceNodeKey].intents) {
          updatedDetails[sourceNodeKey].intents = [];
        }

        const intentExists = updatedDetails[sourceNodeKey].intents.some(
          (intent) => intent.nextNode === newNodeId.toString()
        );

        if (!intentExists) {
          updatedDetails[sourceNodeKey].intents.push({
            trigger: '',
            output_intent: '',
            nextNode: newNodeId.toString(),
            id: `${sourceNodeId}-${newNodeId}`,
            source: sourceNodeId,
            target: newNodeId.toString(),
            animated: true,
            type: 'bezier',
            type: 'action',
          });
        }

        setEdges((eds) => [
          ...eds,
          {
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
            },
            id: `e${sourceNodeId}-${newNodeId}`,
            source: sourceNodeId.toString(),
            target: newNodeId.toString(),
            type: 'action',
            animated: true,
            type: 'bezier',
          },
        ]);
      }

      return updatedDetails;
    });
  };

  // const onNodeClick = (event, node) => {
  //   setCurrentTab('#impostazioniBase');
  //   setSelectedNode(node);
  //   setSelectedEdge(null);
  // };

  const onEdgeClick = (event, edge) => {
    const sourceNodeId = edge.source;
    const existingIntent = dettagliNodi[`nodo-${sourceNodeId}`]?.intents?.find(
      (intent) => intent.nextNode === edge.target
    );

    setSelectedEdge({
      ...edge,
      trigger: existingIntent?.trigger || '',
      output_intent: existingIntent?.output_intent || '',
    });

    setSelectedNode(null);
  };

  const closeModal = () => {
    setSelectedNode(null);
    setShowModalVariables(false);
    setSelectedEdge(null);
  };

  const handleSaveIntent = (trigger, nextNode, output_intent) => {
    setDettagliNodi((prev) => {
      const updatedDetails = { ...prev };
      const sourceNodeId = selectedEdge.source;

      if (!updatedDetails[`nodo-${sourceNodeId}`]?.intents) return prev;

      const intentIndex = updatedDetails[
        `nodo-${sourceNodeId}`
      ]?.intents.findIndex((intent) => intent.nextNode === selectedEdge.target);

      if (intentIndex !== -1) {
        if (!updatedDetails[`nodo-${sourceNodeId}`]) {
          updatedDetails[`nodo-${sourceNodeId}`] = { intents: [] }; // Ensure it exists
        }

        updatedDetails[`nodo-${sourceNodeId}`].intents[intentIndex] = {
          trigger,
          output_intent,
          nextNode,
          id: `${sourceNodeId}-${selectedEdge.target}`,
          source: sourceNodeId,
          target: selectedEdge.target,
          type: 'action',
          animated: true,
          type: 'bezier',
        };
      }

      return updatedDetails;
    });

    closeModal();
  };

  const onEdgesDelete = (deleted) => {
    setEdges((eds) => eds.filter((e) => !deleted.some((d) => d.id === e.id)));
    setDettagliNodi((prev) => {
      const updatedDetails = { ...prev };
      deleted.forEach((edge) => {
        if (updatedDetails[`nodo-${edge.source}`]?.intents) {
          updatedDetails[`nodo-${edge.source}`].intents = updatedDetails[
            `nodo-${edge.source}`
          ].intents.filter((intent) => intent.nextNode !== edge.target);
        }
      });
      return updatedDetails;
    });
  };

  const onNodesDelete = (deleted) => {
    setNodes((nds) => nds.filter((n) => !deleted.some((d) => d.id === n.id)));
    setDettagliNodi((prev) => {
      const updatedDetails = { ...prev };
      deleted.forEach((node) => delete updatedDetails[`nodo-${node.id}`]);
      return updatedDetails;
    });
  };

  const CambiaVersioneConversazionale = (id) => {
    setCurrentConversationalVersion(id);

    let selectedConversation =
      versioniAgenteConversazionale &&
      versioniAgenteConversazionale.find((versione) => versione.id == id);

    let conversazione = selectedConversation.conversationalJSON;

    if (conversazione !== null || conversazione !== '') {
      let editorValueArray = JSON.parse(conversazione);

      if (editorValueArray) {
        if (editorValueArray.viewport) {
          setViewport(editorValueArray.viewport);
        }
        const newNodes = Object.keys(editorValueArray)
          .filter(
            (key) => key !== 'fullfillment_variables' && key !== 'viewport'
          )
          .map((key) => {
            const nodeData = editorValueArray[key];
            return {
              id: nodeData.id.toString(),
              data: { label: nodeData.node_label, actions: [] },
              type: nodeData.type,
              position: nodeData.position,
              sourcePosition: 'right',
              targetPosition: 'left',
            };
          });

        setNodes(newNodes);

        const listFullfillmentVariables =
          editorValueArray.fullfillment_variables || [];

        const fullfillmentVariables = listFullfillmentVariables.filter(
          (fv) =>
            fv.label === '' &&
            fv.nome_variabile === '' &&
            fv.istruzioni_estrazione === ''
        );

        const newDettagliNodi = Object.keys(editorValueArray).reduce(
          (acc, key) => {
            if (key !== 'fullfillment_variables' && key !== 'viewport') {
              const nodeData = editorValueArray[key];
              acc[key] = {
                node_label: nodeData.node_label,
                id: nodeData.id,
                type: nodeData.type,
                sourcePosition: 'right',
                targetPosition: 'left',
                position: nodeData.position,
                intents: nodeData.intents || [],
                fullfillment:
                  fullfillmentVariables.filter(
                    (fv) => fv.nodeId === nodeData.id
                  ) || [],
              };
            }
            return acc;
          },
          {}
        );

        setDettagliNodi(JSON.parse(conversazione));

        const newEdges = [];
        Object.keys(newDettagliNodi).forEach((key) => {
          const node = newDettagliNodi[key];
          if (node.intents) {
            node.intents.forEach((intent) => {
              newEdges.push({
                id: `${node.id}-${intent.nextNode}`,
                source: node.id.toString(),
                target: intent.nextNode.toString(),
                animated: true,
                type: 'bezier',
                markerEnd: {
                  type: MarkerType.Arrow,
                },
                data: {
                  trigger: intent.trigger,
                  output_intent: intent.output_intent,
                  type: 'action',
                  id: intent.id,
                  target: intent.nextNode,
                  source: node.id,
                },
              });
            });
          }
        });

        setEdges(newEdges);
      }
    } else {
      const initialNode = {
        id: '1',
        type: 'start',
        data: { label: 'Nodo 1', actions: [] },
        position: { x: 0, y: 50 },
        sourcePosition: 'right',
      };

      setNodes([initialNode]);
      setDettagliNodi({
        'nodo-1': {
          node_label: 'Nodo 1',
          type: 'start',
          id: 1,
          position: { x: 0, y: 50 },
          sourcePosition: 'right',
        },
      });
    }
  };

  const selectedNodeId = selectedNode ? parseInt(selectedNode.id) : '';
  const nodoKey = selectedNodeId ? `nodo-${selectedNodeId}` : '';
  console.log('nodoKey', nodoKey);

  return (
    <div
      className="position-relative"
      style={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center justify-content-start">
          <select
            className="form-select"
            value={currentConversationalVersion}
            onChange={(e) => CambiaVersioneConversazionale(e.target.value)}
          >
            {Array.isArray(versioniAgenteConversazionale) &&
            versioniAgenteConversazionale.length > 0 ? (
              versioniAgenteConversazionale.map((version) => (
                <option key={version.id} value={version.id}>
                  {version.name}
                  {version.isLive ? ' ⭐' : ''}
                </option>
              ))
            ) : (
              <option disabled>Nessuna versione disponibile</option>
            )}
          </select>
          <button
            data-bs-toggle="modal"
            data-bs-target="#modalPubblicaConversazione"
            className="btn bg-blue-button text-white m-2"
          >
            Salva
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#modalLiveVersion"
            className={` btn d-flex align-items-center p-0 border rounded ${
              versioniAgenteConversazionale &&
              versioniAgenteConversazionale.some(
                (v) =>
                  v.id === parseInt(currentConversationalVersion) && v.isLive
              )
                ? ' text-warning disabled'
                : ''
            }`}
          >
            <span className={`p-2`}>
              <Star />
            </span>
            <span className="bg-warning text-white px-3 py-2">Pubblica</span>
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-start ">
          <button
            onClick={handleAddNode}
            className="btn bg-green-second text-white m-2"
          >
            + Aggiungi Nodo
          </button>
          <button
            // onClick={handleAddNode}
            className="btn bg-green-second text-white m-2"
            data-bs-toggle="modal"
            data-bs-target="#modalJsonEditor"
          >
            <JsonIcon />
          </button>
          {/* <button
            onClick={AggiornaProgetto}
            className="btn bg-blue-button text-white m-2"
          >
            Pubblica
          </button> */}
        </div>
      </div>
      <ReactFlow
        // onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        // style={{ background: '#F5F5F5', flexGrow: 1 }}
        snapToGrid={true}
        snapGrid={snapGrid}
        viewport={viewport}
        onViewportChange={onViewportChange}
        fitView
      >
        {/* Aggiunge lo sfondo a puntini */}
        <Background variant="dots" gap={12} size={2} color="#ccc" />
        <Controls />
      </ReactFlow>

      {selectedNode && (
        <div
          className="d-block"
          tabIndex="-1"
          role="dialog"
          style={{
            backgroundColor: '#F0F0F0',
          }}
        >
          <div className="modal-lg d-flex w-100" role="document">
            <div
              className="content-popup p-4"
              style={{
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.15)',
                borderRadius: '10px',
                border: '1px solid #888888',
                width: '70%', // Occupa il 90% del contenitore relativo
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                minHeight: '700px',
                backgroundColor: '#F0F0F0',
                maxHeight: '700px',
              }}
            >
              <div className="" style={{ backgroundColor: '#F0F0F0' }}>
                <div className="d-flex justify-content-between mb-4">
                  <div></div>
                  <p className="fw-bold">{dettagliNodi[nodoKey]?.node_label}</p>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  {modalTabMenu.map((component, index, array) => (
                    <React.Fragment key={index}>
                      <button
                        className={`btn px-1 py-2 ${
                          currentTab === component.section
                            ? 'tab-menu-conversational-active '
                            : 'tab-menu-conversational-secondary'
                        }`}
                        style={{ width: '500px' }}
                        onClick={() => setCurrentTab(component.section)}
                      >
                        {component.title}
                      </button>
                      {/* Aggiunge la lineetta solo tra i bottoni */}
                    </React.Fragment>
                  ))}
                </div>
                <div>
                  {currentTab === '#impostazioniBase' ? (
                    <ImpostazioniDiBase
                      setCurrentTab={setCurrentTab}
                      closeModal={closeModal}
                      dettagliNodi={dettagliNodi}
                      selectedNode={selectedNode}
                      selectedEdge={selectedEdge} // Passa anche l'edge se selezionato
                      setDettagliNodi={setDettagliNodi}
                    />
                  ) : currentTab === '#inputUtente' ? (
                    <InputUtenteWidget
                      setCurrentTab={setCurrentTab}
                      closeModal={closeModal}
                      dettagliNodi={dettagliNodi}
                      selectedNode={selectedNode}
                      selectedEdge={selectedEdge}
                      setDettagliNodi={setDettagliNodi}
                    />
                  ) : currentTab === '#gestioneVariabili' ? (
                    <GestioneVariabili
                      setCurrentTab={setCurrentTab}
                      closeModal={closeModal}
                      dettagliNodi={dettagliNodi}
                      selectedNode={selectedNode}
                      selectedEdge={selectedEdge}
                      setDettagliNodi={setDettagliNodi}
                    />
                  ) : currentTab === '#apiEsterne' ? (
                    <ApiEsterne
                      setCurrentTab={setCurrentTab}
                      closeModal={closeModal}
                      dettagliNodi={dettagliNodi}
                      selectedNode={selectedNode}
                      selectedEdge={selectedEdge}
                      setDettagliNodi={setDettagliNodi}
                    />
                  ) : (
                    <GenerazioneOutput
                      setCurrentTab={setCurrentTab}
                      closeModal={closeModal}
                      dettagliNodi={dettagliNodi}
                      selectedNode={selectedNode}
                      selectedEdge={selectedEdge}
                      setDettagliNodi={setDettagliNodi}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedEdge && (
        <div
          className="d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: '#F0F0F0' }}
        >
          <div className="modal-lg d-flex w-100" role="document">
            <div
              className="content-popup d-flex flex-column"
              style={{
                borderRadius: '10px',
                overflow: 'hidden',
                border: '1px solid #888888',
                width: '50%',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                minHeight: '580px',
                backgroundColor: '#F0F0F0',
                maxHeight: '580px',
              }}
            >
              <div className="d-flex justify-content-between mb-4 bg-orange py-3 px-3">
                <div></div>
                <p className="fs-5 text-white">{`Intento #${selectedEdge.id}`}</p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="px-4 flex-grow-1">
                <label htmlFor="obiettivo-nodo" className="form-label mt-2">
                  Trigger
                </label>
                <p className="text-secondary mb-3">
                  Indica l'azione che l'utente deve compiere per passare al nodo
                  successivo
                </p>
                <div className="input-group mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Trigger"
                    id="trigger-intent"
                    value={selectedEdge?.trigger || ''}
                    onChange={(e) => {
                      const newTrigger = e.target.value;

                      setSelectedEdge((prev) => ({
                        ...prev,
                        trigger: newTrigger, // Update selectedEdge
                      }));

                      setDettagliNodi((prev) => {
                        const updatedDetails = { ...prev };
                        const sourceNodeId = selectedEdge?.source;

                        if (!sourceNodeId) return prev; // Ensure sourceNodeId is defined

                        // Ensure nodo-{sourceNodeId} exists
                        if (!updatedDetails[`nodo-${sourceNodeId}`]) {
                          updatedDetails[`nodo-${sourceNodeId}`] = {
                            intents: [],
                          };
                        }

                        // Ensure intents array exists
                        if (!updatedDetails[`nodo-${sourceNodeId}`].intents) {
                          updatedDetails[`nodo-${sourceNodeId}`].intents = [];
                        }

                        // Fixing possible typo in key (` nodo-${sourceNodeId}` -> `nodo-${sourceNodeId}`)
                        const intentIndex = updatedDetails[
                          `nodo-${sourceNodeId}`
                        ].intents.findIndex(
                          (intent) => intent.nextNode === selectedEdge?.target
                        );

                        if (intentIndex !== -1) {
                          updatedDetails[`nodo-${sourceNodeId}`].intents[
                            intentIndex
                          ].trigger = newTrigger;
                        }

                        return updatedDetails;
                      });
                    }}
                  />
                </div>

                <label htmlFor="obiettivo-nodo" className="form-label mt-2">
                  Output dell'intent
                </label>
                <p className="text-secondary mb-3">
                  Il messaggio che verrà visualizzato dall'utente alla scelta
                  dell'intent
                </p>
                <div className="input-group mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Output dell'intent"
                    id="output_intent"
                    value={selectedEdge?.output_intent || ''}
                    onChange={(e) => {
                      const newOutputIntent = e.target.value;

                      setSelectedEdge((prev) => ({
                        ...prev,
                        output_intent: newOutputIntent, // Update selectedEdge
                      }));

                      setDettagliNodi((prev) => {
                        const updatedDetails = { ...prev };
                        const sourceNodeId = selectedEdge?.source;

                        if (!sourceNodeId) return prev; // Ensure sourceNodeId is defined

                        // Ensure nodo-{sourceNodeId} exists
                        if (!updatedDetails[`nodo-${sourceNodeId}`]) {
                          updatedDetails[`nodo-${sourceNodeId}`] = {
                            intents: [],
                          };
                        }

                        // Ensure intents array exists
                        if (!updatedDetails[`nodo-${sourceNodeId}`].intents) {
                          updatedDetails[`nodo-${sourceNodeId}`].intents = [];
                        }

                        // Fixing possible typo in key (` nodo-${sourceNodeId}` -> `nodo-${sourceNodeId}`)
                        const intentIndex = updatedDetails[
                          `nodo-${sourceNodeId}`
                        ].intents.findIndex(
                          (intent) => intent.nextNode === selectedEdge?.target
                        );

                        if (intentIndex !== -1) {
                          updatedDetails[`nodo-${sourceNodeId}`].intents[
                            intentIndex
                          ].output_intent = newOutputIntent;
                        }

                        return updatedDetails;
                      });
                    }}
                  />
                </div>

                <label
                  htmlFor="info-nodo-collegato"
                  className="form-label mt-2"
                >
                  Nodo collegato
                </label>
                <p id="info-nodo-collegato">
                  {nodes.find((node) => node.id === selectedEdge.target)
                    ? `Nodo ${
                        nodes.find((node) => node.id === selectedEdge.target).id
                      } - ${
                        nodes.find((node) => node.id === selectedEdge.target)
                          .data.label
                      }`
                    : 'Nessun nodo selezionato'}
                </p>
              </div>
              {/* Add the button here, make sure it's aligned at the bottom */}
              <div className="d-flex justify-content-end mt-auto p-4 gap-2">
                <button className="btn btn-secondary" onClick={closeModal}>
                  Annulla
                </button>
                <button
                  onClick={() =>
                    handleSaveIntent(
                      selectedEdge.trigger,
                      selectedEdge.target,
                      selectedEdge.output_intent
                    )
                  }
                  className="btn btn-primary"
                >
                  Salva
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      <ModalSaveConversation
        versioniAgenteConversazionale={versioniAgenteConversazionale}
        edito
        setDettagliNodi={setDettagliNodi}
        currentConversationalVersion={currentConversationalVersion}
        setCurrentConversationalVersion={setCurrentConversationalVersion}
        setVersioniAgenteConversazionale={setVersioniAgenteConversazionale}
        dettagliNodi={dettagliNodi}
        id={id}
        nodes={nodes}
        setNodes={setNodes}
        viewport={viewport}
        edges={edges}
        setEdges={setEdges}
      />
      <ModalLiveVersion
        versioniAgenteConversazionale={versioniAgenteConversazionale}
        setDettagliNodi={setDettagliNodi}
        currentConversationalVersion={currentConversationalVersion}
        setCurrentConversationalVersion={setCurrentConversationalVersion}
        dettagliNodi={dettagliNodi}
        setVersioniAgenteConversazionale={setVersioniAgenteConversazionale}
        id={id}
        nodes={nodes}
      />
      <ModalJsonEditor
        versioniAgenteConversazionale={versioniAgenteConversazionale}
        setDettagliNodi={setDettagliNodi}
        currentConversationalVersion={currentConversationalVersion}
        setCurrentConversationalVersion={setCurrentConversationalVersion}
        dettagliNodi={dettagliNodi}
        setVersioniAgenteConversazionale={setVersioniAgenteConversazionale}
        id={id}
        nodes={nodes}
        setNodes={setNodes}
        edges={edges}
        setEdges={setEdges}
      />
    </div>
  );
};

export default KnotsEditor;
