import React, { useState, useEffect, useContext } from 'react';
import { PiShuffleFill as ShuffleIcon } from 'react-icons/pi';
import { Button } from 'react-bootstrap';
import { IoMdSettings as SettingsIcon } from 'react-icons/io';
import { FaUnlink as UnlinkIcon } from 'react-icons/fa';
import { StateContext } from '../../StateContainer';
import ModalVariables from '../modal/ModalVariables';

const GestioneVariabili = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const { showModalVariables, setShowModalVariables } =
    useContext(StateContext);
  const [label, setLabel] = useState('');
  const [nomeVariabile, setNomeVariabile] = useState('');
  const [istruzioni, setIstruzioni] = useState('');
  const [variabileSelezionata, setVariabileSelezionata] = useState(null);
  const [variabiliSelezionate, setVariabiliSelezionate] = useState([]);

  useEffect(() => {
    if (selectedNode) {
      setVariabiliSelezionate(
        dettagliNodi?.[`nodo-${selectedNode?.id}`]?.node_variables || []
      );
    }
  }, [selectedNode]);

  const handleRemoveVariable = (variabile) => {
    setDettagliNodi((prev) => {
      const nodoKey = `nodo-${selectedNode.id}`;
      const updatedNodeVariables = prev[nodoKey]?.node_variables.filter(
        (v) => v.label !== variabile.label
      );

      return {
        ...prev,
        [nodoKey]: {
          ...prev[nodoKey],
          node_variables: updatedNodeVariables,
        },
      };
    });

    setVariabiliSelezionate((prev) =>
      prev.filter((v) => v.label !== variabile.label)
    );
  };

  const handleToggleRequired = (variabile) => {
    setDettagliNodi((prev) => {
      const nodoKey = `nodo-${selectedNode.id}`;
      const updatedNodeVariables = prev[nodoKey]?.node_variables.map((v) =>
        v.label === variabile.label ? { ...v, required: !v.required } : v
      );

      return {
        ...prev,
        [nodoKey]: {
          ...prev[nodoKey],
          node_variables: updatedNodeVariables,
        },
      };
    });
  };

  const handleOpenModal = () => setShowModalVariables(true);

  console.log(showModalVariables);

  const handleSetAction = (variabile, nuovoValore) => {
    setDettagliNodi((prev) => {
      const nodoKey = `nodo-${selectedNode.id}`;
      const updatedNodeVariables = prev[nodoKey]?.node_variables.map((v) =>
        v.label === variabile.label ? { ...v, action: nuovoValore } : v
      );

      return {
        ...prev,
        [nodoKey]: {
          ...prev[nodoKey],
          node_variables: updatedNodeVariables,
        },
      };
    });
  };

  return (
    <div className="row">
      <>
        {dettagliNodi?.[`nodo-${selectedNode?.id}`]?.node_variables?.length >
        0 ? (
          <>
            <p className="text-center mt-3">
              Seleziona le variabili che il nodo si aspetta di ricevere
            </p>
            <table
              className="table"
              style={{ minHeight: '', overflowY: 'scroll' }}
            >
              <thead>
                <tr>
                  <th>Azione</th>
                  <th>Nome Variabile</th>
                  <th>Richiesto</th>
                  <th>Rimuovi</th>
                </tr>
              </thead>
              <tbody>
                {dettagliNodi[`nodo-${selectedNode.id}`]?.node_variables.map(
                  (variable) => (
                    <tr key={variable.label}>
                      <td>
                        <select
                          className="form-select"
                          value={variable.action}
                          onChange={(e) =>
                            handleSetAction(variable, e.target.value)
                          }
                        >
                          <option value="acquisisci">Acquisisci</option>
                          <option value="rimuovi">Rimuovi</option>
                          <option value="utilizza">Utilizza</option>
                        </select>
                      </td>
                      <td>{variable.label}</td>

                      <td className="text-center">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id={`switch-${variable.label}`}
                            checked={variable.required || false}
                            onChange={() => handleToggleRequired(variable)}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <span
                          onClick={() => handleRemoveVariable(variable)}
                          style={{ cursor: 'pointer' }}
                        >
                          <UnlinkIcon />
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <div className="d-flex align-items-center justify-content-between mt-auto">
              <button
                className="btn bg-green-second text-white"
                onClick={() => setShowModalVariables(!showModalVariables)}
              >
                Associa nuova variabile
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="text-center mb-4 mt-3">
              Seleziona le variabili che il nodo si aspetta di ricevere
            </p>
            <div className="d-flex flex-column align-items-center justify-content-center mt-5 mb-0">
              <ShuffleIcon style={{ fontSize: '50px', color: '#557CD3' }} />
              <p>Non hai ancora creato una variabile nel progetto?</p>
              <button
                className="btn bg-green-second text-white mt-5"
                onClick={handleOpenModal}
              >
                Associa nuova variabile
              </button>
            </div>
          </>
        )}
      </>

      {showModalVariables && (
        <ModalVariables
          closeModal={closeModal}
          setCurrentTab={setCurrentTab}
          dettagliNodi={dettagliNodi}
          selectedNode={selectedNode}
          setDettagliNodi={setDettagliNodi}
        />
      )}
    </div>
  );
};

export default GestioneVariabili;
