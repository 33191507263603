import React, { useEffect, useContext, useState } from 'react';
import { StateContext } from '../../StateContainer';
import axios from 'axios';
import { IoCheckmarkDoneCircleSharp as IconaFatto } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const ModalLiveVersion = ({
  versioniAgenteConversazionale,
  setVersioniAgenteConversazionale,
  setDettagliNodi,
  nodes,
  dettagliNodi,
  currentConversationalVersion,
  id,
}) => {
  const { error, setError, token, nuovoProgetto, setNuovoProgetto } =
    useContext(StateContext);

  const [titoloProgetto, setTitoloProgetto] = useState('');
  const [tipoProgetto, setTipoProgetto] = useState('conversational');
  const [loadingProject, setLoadingProject] = useState(false);
  const [selectedVersionToUpload, setSelectedVersionToUpload] = useState(
    currentConversationalVersion
  );
  const [versionLive, setVersionLive] = useState(false);

  useEffect(() => {
    setSelectedVersionToUpload(currentConversationalVersion);
  }, [currentConversationalVersion]);

  const closeModalCreateButton = document.getElementById(
    'modalLiveVersionButton'
  );

  const AggiornaProgetto = (event) => {
    // Aggiorna le posizioni dei nodi con lo stato più recente
    setDettagliNodi((prev) => {
      const updatedDetails = { ...prev };
      nodes.forEach((node) => {
        if (updatedDetails[`nodo-${node.id}`]) {
          updatedDetails[`nodo-${node.id}`].position = node.position;
        }
      });
      return updatedDetails;
    });

    // Esegui la chiamata API con i dati aggiornati
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
        liveVersion: selectedVersionToUpload,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        } else {
          // setDettagliNodi(
          //   JSON.parse(response.data.response.projects.conversationalJSON)
          // );
          setVersioniAgenteConversazionale(
            response.data.response.projects.versionsConversational
          );

          toast.success(
            'La versione selezionata è stata pubblicata correttamente'
          );

          if (closeModalCreateButton) {
            closeModalCreateButton.click();
          }
        }
      })
      .catch((err) => setError(err));
  };

  return (
    <div
      className="modal fade overlay"
      id="modalLiveVersion"
      tabIndex="-1"
      aria-labelledby="modalLiveVersion"
      aria-hidden="true"
      data-bs-backdrop="false"
      style={{ zIndex: '99999999999999999999999999999999999' }}
    >
      <div className="modal-dialog modal-dialog-centered text-center">
        <div className="modal-content">
          <div className="modal-header border-0 py-4 text-center">
            <h5 className="modal-title text-center">Pubblica online</h5>
          </div>
          <div className="modal-body">
            <p>
              Confermi di procedere con la pubblicazione online di questa
              versione
            </p>
            <hr />
            <h3>
              {(() => {
                const versioneCorrente =
                  versioniAgenteConversazionale &&
                  versioniAgenteConversazionale.find(
                    (v) => v.id === parseInt(currentConversationalVersion)
                  );
                return versioneCorrente ? `${versioneCorrente.name}` : 'h';
              })()}
            </h3>
          </div>
          <div className="modal-footer">
            <div className="d-flex w-100 gap-2">
              <button
                type="button"
                className="btn btn-danger w-50"
                data-bs-dismiss="modal"
                id="modalLiveVersionButton"
              >
                Annulla
              </button>
              <button
                type="button"
                className="btn bg-warning text-white w-50"
                onClick={AggiornaProgetto}
                id="creaProgettoButton"
              >
                Pubblica
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLiveVersion;
