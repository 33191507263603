import React, { useState, useRef, useContext } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import { StateContext } from '../../StateContainer';

const Loading = () => <div>Loading</div>;

const ImpostazioniDiBase = ({
  closeModal,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const { setCurrentTab, currentTab } = useContext(StateContext);
  const selectedNodeId = parseInt(selectedNode.id);
  const nodoKey = `nodo-${selectedNodeId}`;
  const rta = useRef(null);

  const Item = ({ entity: { nome_variabile } }) => {
    if (!nome_variabile) return null;
    return <div>{nome_variabile}</div>;
  };

  console.log(dettagliNodi);
  // Aggiornamento diretto dello stato globale
  const updateNodeDetails = (field, value) => {
    setDettagliNodi((prev) => ({
      ...prev,
      [nodoKey]: {
        ...prev[nodoKey],
        [field]: value,
      },
    }));
  };

  const filteredVariables = Array.isArray(dettagliNodi[nodoKey]?.node_variables)
    ? dettagliNodi[nodoKey].node_variables.filter((variable) =>
        variable.nome_variabile.toLowerCase()
      )
    : [];

  return (
    <div className="mt-3 " style={{ height: '530px' }}>
      <div className="d-flex gap-2">
        <div className="w-100">
          <label htmlFor="basic-url" className="form-label">
            Nome del nodo
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              id="basic-url"
              value={dettagliNodi[nodoKey]?.node_label || ''}
              onChange={(e) => updateNodeDetails('node_label', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100">
          <label>Tipologia di servizio esterno</label>
          <select
            className="form-select mt-2"
            value={dettagliNodi[nodoKey]?.type}
            onChange={(e) => updateNodeDetails('type', e.target.value)}
          >
            <option value="">Seleziona</option>
            <option value="start">Inizio</option>
            <option value="end">Fine</option>
            <option value="execution">Esecuzione</option>
          </select>
        </div>
      </div>

      <label htmlFor="obiettivo-nodo" className="form-label mt-2">
        Obiettivo del nodo
      </label>
      <div className="input-group mb-3">
        <textarea
          id="obiettivo-nodo"
          placeholder="Fornisci istruzioni alla AI per raggiungere l'obiettivo del nodo"
          className="form-control"
          value={dettagliNodi[nodoKey]?.description || ''}
          onChange={(e) => updateNodeDetails('description', e.target.value)}
        ></textarea>
      </div>

      <label htmlFor="obiettivo-nodo" className="form-label mt-2">
        Messaggio dell'assistente
      </label>
      <div className="input-group mb-3 position-relative">
        <ReactTextareaAutocomplete
          className="form-control"
          loadingComponent={Loading}
          style={{ lineHeight: '20px', padding: 10, fontSize: '16px' }}
          listClassName={'lista-suggerimenti-variabili'}
          ref={rta}
          minChar={0}
          trigger={{
            '{': {
              dataProvider: (token) =>
                filteredVariables.map(({ nome_variabile }) => ({
                  nome_variabile,
                })),
              component: Item,
              output: (item) => `{${item.nome_variabile}}`,
            },
          }}
          value={dettagliNodi[nodoKey]?.output_assistant || ''}
          onChange={(e) =>
            updateNodeDetails('output_assistant', e.target.value)
          }
        />
      </div>

      <div>
        <label htmlFor="flexSwitchCheckChecked" className="form-label">
          Disabilita input utente
        </label>
        <p className="text-secondary mb-2">
          Se attivo, l'input sarà possibile solo tramite widget
        </p>
        <div className="form-check form-switch mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            checked={dettagliNodi[nodoKey]?.disable_user_text || false}
            onChange={(e) =>
              updateNodeDetails('disable_user_text', e.target.checked)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ImpostazioniDiBase;
