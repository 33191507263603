import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { PiShuffleFill as ShuffleIcon } from 'react-icons/pi';

const TestoLiberoWidget = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
  handleOpenModal,
}) => {
  const [selectedVariable, setSelectedVariable] = useState(null);
  useEffect(() => {
    if (selectedNode) {
      const nodeData =
        dettagliNodi[`nodo-${selectedNode.id}`]?.widgets?.[0] || {};
      setSelectedVariable(
        nodeData.variable_name
          ? { value: nodeData.variable_name, label: nodeData.variable_name }
          : null
      );
    }
  }, [selectedNode, dettagliNodi]);

  const nodeVariables =
    dettagliNodi &&
    dettagliNodi[`nodo-${selectedNode?.id}`] &&
    dettagliNodi[`nodo-${selectedNode?.id}`].node_variables;

  // Creiamo le opzioni per il select
  const variable_options = nodeVariables
    ? [
        { value: null, label: 'Nessuna variabile' }, // Aggiunta l'opzione "Nessuna variabile"
        ...nodeVariables.map((variable) => ({
          value: variable.label,
          label: variable.label,
        })),
      ]
    : [{ value: '', label: 'Nessuna variabile disponibile' }];

  return (
    <div className="row" style={{ height: '530px' }}>
      <div className="col-12" style={{ borderLeft: '1px black solid' }}>
        <div className="d-flex flex-column ps-1" style={{ height: '100%' }}>
          {/* Sezione bottoni allineata in basso */}
        </div>
      </div>
    </div>
  );
};

export default TestoLiberoWidget;
