import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const RispostaChiusa = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const nodoKey = `nodo-${selectedNode.id}`;
  const nodeData = dettagliNodi[nodoKey]?.widgets?.[0] || {};
  const [currentChoice, setCurrentChoice] = useState(null);

  useEffect(() => {
    if (!dettagliNodi[nodoKey]) {
      setDettagliNodi((prev) => ({
        ...prev,
        [nodoKey]: {
          widgets: [
            { type: 'select', content: '', variable_name: null, choices: [] },
          ],
        },
      }));
    }
  }, [selectedNode, setDettagliNodi]);

  const updateNodeDetails = (field, value) => {
    setDettagliNodi((prev) => ({
      ...prev,
      [nodoKey]: {
        ...prev[nodoKey],
        widgets: prev[nodoKey]?.widgets
          ? prev[nodoKey].widgets.map((widget) => ({
              ...widget,
              [field]: value,
            }))
          : [{ [field]: value, choices: [] }],
      },
    }));
  };

  const handleSaveChoice = () => {
    if (currentChoice?.label && currentChoice?.content) {
      setDettagliNodi((prev) => ({
        ...prev,
        [nodoKey]: {
          ...prev[nodoKey],
          widgets: prev[nodoKey]?.widgets.map((widget) => ({
            ...widget,
            choices: widget.choices
              ? currentChoice.id
                ? widget.choices.map((choice) =>
                    choice.id === currentChoice.id
                      ? { ...choice, ...currentChoice } // Update the existing choice
                      : choice
                  )
                : [
                    ...widget.choices,
                    { ...currentChoice, id: new Date().getTime() }, // Add the new choice with a unique ID
                  ]
              : [{ ...currentChoice, id: new Date().getTime() }], // If no choices exist, create the first one
          })),
        },
      }));
      setCurrentChoice(null); // Reset current choice after saving
    }
  };

  const filteredNodes = Object.keys(dettagliNodi)
    .map((nodeKey) => {
      const node = dettagliNodi[nodeKey];
      return { value: node.id, label: node.node_label };
    })
    .filter((node) => node.value !== undefined && node.label !== undefined);

  console.log(filteredNodes);

  const nodes_options = filteredNodes.length > 0 ? filteredNodes : [];

  const handleRemoveChoice = (choiceToRemove) => {
    setDettagliNodi((prev) => ({
      ...prev,
      [nodoKey]: {
        ...prev[nodoKey],
        widgets: prev[nodoKey]?.widgets.map((widget) => ({
          ...widget,
          choices: widget.choices
            ? widget.choices.filter((choice) => choice.id !== choiceToRemove.id)
            : [],
        })),
      },
    }));
    setCurrentChoice(null);
  };

  const nodeVariables = dettagliNodi?.[nodoKey]?.node_variables || [];
  const variable_options = nodeVariables.map((variable) => ({
    value: variable.nome_variabile,
    label: variable.label,
  }));

  return (
    <div className="row" style={{ height: '530px' }}>
      <div
        className="col-6 ps-3 d-flex flex-column h-100"
        style={{ borderLeft: '1px black solid' }}
      >
        <label>Visualizza come</label>
        <select
          className="form-select mt-2 mb-3"
          value={nodeData.type === 'button' ? '2' : '1'}
          onChange={(e) =>
            updateNodeDetails(
              'type',
              e.target.value === '1' ? 'select' : 'button'
            )
          }
        >
          <option value="1">Dropdown</option>
          <option value="2">Bottoni</option>
        </select>

        <label>Label</label>
        <input
          type="text"
          className="form-control mt-2"
          value={nodeData.content || ''}
          onChange={(e) => updateNodeDetails('content', e.target.value)}
        />

        <label className="mt-3 mb-2">Seleziona variabile</label>
        <Select
          options={variable_options}
          value={
            variable_options.find(
              (option) => option.value === nodeData.variable_name
            ) || null
          }
          onChange={(option) =>
            updateNodeDetails('variable_name', option ? option.value : null)
          }
          placeholder="Cerca..."
          isSearchable
        />

        <label className="mt-2">Elenco scelte</label>
        <div className="overflow-choice-menu">
          {nodeData.choices?.length ? (
            nodeData.choices.map((choice) => (
              <div
                key={choice.id}
                className="scelta-widget"
                onClick={() => setCurrentChoice(choice)}
              >
                {choice.label}
              </div>
            ))
          ) : (
            <p className="text-secondary my-2">Ancora nessuna scelta</p>
          )}
        </div>
        <button
          type="button"
          className="btn bg-blue-button text-white w-100"
          onClick={() => setCurrentChoice({ label: '', content: '', id: null })}
        >
          Aggiungi scelta
        </button>
      </div>

      {currentChoice && (
        <div
          className="col-6 d-flex flex-column h-100"
          style={{ borderLeft: '1px black solid' }}
        >
          <label>Etichetta della scelta</label>
          <input
            type="text"
            className="form-control mb-3"
            value={currentChoice.label}
            onChange={(e) =>
              setCurrentChoice({ ...currentChoice, label: e.target.value })
            }
          />

          <label>Testo in chat</label>
          <textarea
            className="form-control"
            style={{ height: '100px' }}
            value={currentChoice.content}
            onChange={(e) =>
              setCurrentChoice({ ...currentChoice, content: e.target.value })
            }
          ></textarea>
          <label className="mt-3">Seleziona nodo</label>
          <Select
            options={nodes_options}
            placeholder="Cerca..."
            isSearchable
            value={nodes_options.find(
              (option) => option.value === currentChoice?.nextNode // Set the selected option based on nextNode
            )}
            onChange={
              (option) =>
                setCurrentChoice({ ...currentChoice, nextNode: option?.value }) // Update nextNode when a selection is made
            }
          />

          <div className="mt-auto d-flex justify-content-between gap-2">
            <span
              className="bg-transparent text-danger"
              style={{ cursor: 'pointer' }}
              onClick={() => handleRemoveChoice(currentChoice)}
            >
              Rimuovi scelta
            </span>
            <div className="d-flex gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setCurrentChoice(null)}
              >
                Annulla
              </button>
              <button
                className="btn bg-blue-button text-white"
                onClick={handleSaveChoice}
              >
                Salva Scelta
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RispostaChiusa;
