import React, { useEffect, useContext, useState } from 'react';
import { StateContext } from '../../StateContainer';
import axios from 'axios';
import { IoCheckmarkDoneCircleSharp as IconaFatto } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { MarkerType } from '@xyflow/react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModalSaveConversation = ({
  versioniAgenteConversazionale,
  setVersioniAgenteConversazionale,
  setDettagliNodi,
  nodes,
  dettagliNodi,
  currentConversationalVersion,
  setCurrentConversationalVersion,
  setNodes,
  setEdges,
  viewport,
  id,
}) => {
  const { error, setError, token } = useContext(StateContext);

  const [selectedVersionToUpload, setSelectedVersionToUpload] = useState(
    currentConversationalVersion
  );
  const [newVersionName, setNewVersionName] = useState('');
  const [useExistingVersion, setUseExistingVersion] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setSelectedVersionToUpload(currentConversationalVersion);
  }, [currentConversationalVersion]);

  useEffect(() => {
    if (
      !versioniAgenteConversazionale ||
      versioniAgenteConversazionale.length === 0
    ) {
      setUseExistingVersion(false);
    }
  }, [versioniAgenteConversazionale]);

  const closeModalCreateButton = document.getElementById(
    'modalPubblicaConversazioneButton'
  );

  const AggiornaProgetto = () => {
    setDettagliNodi((prev) => {
      const updatedDetails = { ...prev };
      nodes.forEach((node) => {
        if (updatedDetails[`nodo-${node.id}`]) {
          updatedDetails[`nodo-${node.id}`].position = node.position;
        }
      });
      return updatedDetails;
    });

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/version/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
        useExsistingVersion: useExistingVersion,
        version: useExistingVersion ? selectedVersionToUpload : newVersionName,
        conversationalJSON: JSON.stringify({
          ...dettagliNodi,
          viewport: viewport,
        }),
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        } else {
          setUseExistingVersion(true);
          setNewVersionName('');
          setShowWarning(false);
          setCurrentConversationalVersion(response.data.response.id);
          setSelectedVersionToUpload(response.data.response.id);

          // Modifico lo schema
          let editorValueArray = JSON.parse(
            response.data.response.conversationalJSON
          );

          if (editorValueArray) {
            const newNodes = Object.keys(editorValueArray)
              .filter(
                (key) => key !== 'fullfillment_variables' && key !== 'viewport'
              )
              .map((key) => {
                const nodeData = editorValueArray[key];
                return {
                  id: nodeData.id.toString(),
                  type: nodeData.type,
                  data: { label: nodeData.node_label, actions: [] },
                  position: nodeData.position,
                  sourcePosition: 'right',
                  targetPosition: 'left',
                };
              });

            setNodes(newNodes);

            const listFullfillmentVariables =
              editorValueArray.fullfillment_variables || [];

            const fullfillmentVariables = listFullfillmentVariables.filter(
              (fv) =>
                fv.label === '' &&
                fv.nome_variabile === '' &&
                fv.istruzioni_estrazione === ''
            );

            const newDettagliNodi = Object.keys(editorValueArray).reduce(
              (acc, key) => {
                if (key !== 'fullfillment_variables' && key !== 'viewport') {
                  const nodeData = editorValueArray[key];
                  acc[key] = {
                    node_label: nodeData.node_label,
                    id: nodeData.id,
                    type: nodeData.type,
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    position: nodeData.position,
                    intents: nodeData.intents || [],
                    fullfillment:
                      fullfillmentVariables.filter(
                        (fv) => fv.nodeId === nodeData.id
                      ) || [],
                  };
                }
                return acc;
              },
              {}
            );

            const newEdges = [];
            Object.keys(newDettagliNodi).forEach((key) => {
              const node = newDettagliNodi[key];
              if (node.intents) {
                node.intents.forEach((intent) => {
                  newEdges.push({
                    id: `${node.id}-${intent.nextNode}`,
                    source: node.id.toString(),
                    target: intent.nextNode.toString(),
                    animated: true,
                    type: 'bezier',
                    markerEnd: {
                      type: MarkerType.ArrowClosed,
                      width: 20,
                      height: 20,
                    },
                    data: {
                      trigger: intent.trigger,
                      output_intent: intent.output_intent,
                      type: 'action',
                      id: intent.id,
                      target: intent.nextNode,
                      source: node.id,
                    },
                  });
                });
              }
            });

            setEdges(newEdges);
          }

          // Dettagli nodi
          setDettagliNodi(
            JSON.parse(response.data.response.conversationalJSON)
          );
          setVersioniAgenteConversazionale(
            response.data.response.versionsConversational
          );
          if (closeModalCreateButton) {
            closeModalCreateButton.click();
          }
          toast.success(
            'Le modifiche della versione "' +
              response.data.response.name +
              '" sono state salvate'
          );
        }
      })
      .catch((err) => setError(err));
  };

  return (
    <div
      className="modal fade overlay"
      id="modalPubblicaConversazione"
      tabIndex="-1"
      aria-labelledby="modalPubblicaConversazione"
      aria-hidden="true"
      data-bs-backdrop="false"
      style={{ zIndex: '99999999999999999999999999999999999' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 py-4">
            <h5 className="modal-title">Salva</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="close-modal-create"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* Sovrascrivi vecchia versione */}
            {/* Sovrascrivi vecchia versione */}
            {versioniAgenteConversazionale &&
              versioniAgenteConversazionale.length > 0 && (
                <div className="form-outline">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={useExistingVersion}
                    onChange={() => setUseExistingVersion(true)}
                  />
                  <label className="form-label ms-1">
                    Sovrascrivi vecchia versione
                  </label>
                  <select
                    className="form-select"
                    value={selectedVersionToUpload}
                    onChange={(e) => {
                      setSelectedVersionToUpload(e.target.value);
                      setShowWarning(
                        parseInt(e.target.value) !==
                          parseInt(currentConversationalVersion)
                      );
                    }}
                    disabled={!useExistingVersion}
                  >
                    {versioniAgenteConversazionale.map((version) => (
                      <option key={version.id} value={version.id}>
                        {version.name} {version.isLive ? ' ⭐' : ''}
                      </option>
                    ))}
                  </select>
                </div>
              )}

            {/* Crea nuova versione */}
            <div className="form-outline gap-2 my-4">
              <input
                type="checkbox"
                className="form-check-input"
                checked={
                  !useExistingVersion ||
                  !versioniAgenteConversazionale ||
                  versioniAgenteConversazionale.length === 0
                }
                onChange={() => setUseExistingVersion(false)}
                disabled={
                  !versioniAgenteConversazionale ||
                  versioniAgenteConversazionale.length === 0
                }
              />
              <label className="form-label ms-1">Crea nuova versione</label>

              <input
                type="text"
                className="form-control"
                value={newVersionName}
                onChange={(e) => setNewVersionName(e.target.value)}
                disabled={useExistingVersion}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="d-flex w-100 gap-2">
              <button
                type="button"
                className="btn btn-danger w-50"
                data-bs-dismiss="modal"
                id="modalPubblicaConversazioneButton"
              >
                Annulla
              </button>
              <button
                type="button"
                className="btn bg-blue-header text-white w-50"
                onClick={AggiornaProgetto}
                id="creaProgettoButton"
              >
                Salva
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSaveConversation;
