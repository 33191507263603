import { useState, useContext, useEffect } from 'react';
import { StateContext } from '../../StateContainer';
import ModalVariables from '../modal/ModalVariables';
import Select from 'react-select';
import axios from 'axios';

const GenerazioneOutput = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const {
    showModalVariables,
    setShowModalVariables,
    token,
    setLoading,
    setError,
  } = useContext(StateContext);

  const [listOfProjects, setListOfProjects] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: 'Usa risposta assistente',
  });

  const nodoDetails = dettagliNodi[`nodo-${selectedNode.id}`] || {};
  const initialSwitchState =
    nodoDetails.tailorgpt_project_reference ||
    nodoDetails.internal_knowledge_default_fallback ||
    nodoDetails.external_service_response;
  const [isSwitchOn, setIsSwitchOn] = useState(!!initialSwitchState);

  const [variableOptions, setVariableOptions] = useState([
    { value: '', label: 'Usa risposta assistente' },
    { value: 'internal-knowledge', label: 'Usa conoscenza interna' },
    {
      value: 'external-service-response',
      label: 'Usa risposta servizio esterno',
    },
  ]);

  useEffect(() => {
    setLoading(true);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/project/list`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        setLoading(false);
        setError('');
        const projects = response.data.response.projects;

        if (projects && projects.length > 0) {
          const newOptions = projects.map((project) => ({
            value: project.code,
            label: 'Usa conoscenza progetto "' + project.title + '"',
          }));

          setVariableOptions((prevOptions) => {
            const existingCodes = new Set(
              prevOptions.map((option) => option.value)
            );
            const filteredNewOptions = newOptions.filter(
              (option) => !existingCodes.has(option.value)
            );
            return [...prevOptions, ...filteredNewOptions];
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        }
      });
  }, []);

  const handleSwitchToggle = () => {
    setIsSwitchOn((prev) => {
      const newState = !prev;
      if (!newState) {
        handleSelectChange({ value: '', label: 'Usa risposta assistente' });
      }
      return newState;
    });
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setIsSwitchOn(option.value !== '');

    setDettagliNodi((prev) => {
      const nodoKey = `nodo-${selectedNode.id}`;
      const nodoDetails = prev[nodoKey] || {};

      const newDetails = {
        ...prev,
        [nodoKey]: {
          ...nodoDetails,
          tailorgpt_project_reference:
            option.value !== 'internal-knowledge' &&
            option.value !== 'external-service-response'
              ? option.value
              : nodoDetails.tailorgpt_project_reference,
          internal_knowledge_default_fallback:
            option.value === 'internal-knowledge',
          external_service_response:
            option.value === 'external-service-response',
        },
      };

      return newDetails;
    });
  };

  useEffect(() => {
    const savedValue = nodoDetails.external_service_response
      ? 'external-service-response'
      : nodoDetails.internal_knowledge_default_fallback
      ? 'internal-knowledge'
      : nodoDetails.tailorgpt_project_reference || '';

    const foundOption = variableOptions.find((opt) => opt.value === savedValue);
    setSelectedOption(
      foundOption || { value: '', label: 'Usa risposta assistente' }
    );
  }, [dettagliNodi, variableOptions]);

  return (
    <>
      {!showModalVariables ? (
        <div className="row my-3" style={{ height: '530px' }}>
          <div className="widget-tabs col-12 d-flex flex-column">
            <div className="d-flex align-items-center gap-2 justify-content-start">
              <label>Se non capisci il comportamento dell'utente</label>
              <div className="form-check form-switch py-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={isSwitchOn}
                  onChange={handleSwitchToggle}
                />
              </div>
            </div>
            <p className="text-secondary mb-3">
              Comportamento dell’AI se non capisce l’intenzione dell’utente.
            </p>
            <Select
              options={variableOptions}
              placeholder="Cerca..."
              isSearchable
              isDisabled={!isSwitchOn}
              value={selectedOption}
              onChange={handleSelectChange}
            />
          </div>
        </div>
      ) : (
        <ModalVariables
          closeModal={closeModal}
          setCurrentTab={setCurrentTab}
          dettagliNodi={dettagliNodi}
          selectedNode={selectedNode}
          setDettagliNodi={setDettagliNodi}
        />
      )}
    </>
  );
};

export default GenerazioneOutput;
