import React, { useEffect, useContext, useState } from 'react';
import { StateContext } from '../../StateContainer';
import Editor from '@monaco-editor/react';
import 'draft-js/dist/Draft.css'; // Importa il CSS per la corretta visualizzazione
import { useParams } from 'react-router-dom';
import 'moment/locale/it';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactFlow, Background, MarkerType } from '@xyflow/react';
import 'react-json-view-lite/dist/index.css';

const ModalJsonEditor = ({
  versioniAgenteConversazionale,
  setVersioniAgenteConversazionale,
  setDettagliNodi,
  nodes,
  dettagliNodi,
  currentConversationalVersion,
  setCurrentConversationalVersion,
  setNodes,
  setEdges,
}) => {
  const {
    setDominioDelSito,
    setLoading,
    aggiornamentoDatiProgetto,
    setError,
    token,
  } = useContext(StateContext);

  const { id } = useParams();
  const [conversationalJson, setConversationalJson] = useState('');
  const [aggiornatoNodiConversazione, setAggiornatoNodiConversazione] =
    useState(false);
  const [erroreNodiConversazione, setErroreNodiConversazione] = useState(false);

  const closeModalCreateButton = document.getElementById(
    'modalEditorJsonButton'
  );

  const AggiornaNodiConversazione = () => {
    setAggiornatoNodiConversazione(false);
    setErroreNodiConversazione(false);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/version/update`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
        version: currentConversationalVersion,
        useExsistingVersion: true,
        conversationalJSON: JSON.stringify(dettagliNodi),
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        } else {
          setCurrentConversationalVersion(response.data.response.id);

          // Modifico lo schema
          let editorValueArray = JSON.parse(
            response.data.response.conversationalJSON
          );

          if (editorValueArray) {
            const newNodes = Object.keys(editorValueArray)
              .filter((key) => key !== 'fullfillment_variables')
              .map((key) => {
                const nodeData = editorValueArray[key];
                return {
                  id: nodeData.id.toString(),
                  data: { label: nodeData.node_label, actions: [] },
                  position: nodeData.position,
                  sourcePosition: 'right',
                  targetPosition: 'left',
                };
              });

            setNodes(newNodes);

            console.log('qu9i', editorValueArray);

            const listFullfillmentVariables =
              editorValueArray.fullfillment_variables || [];

            const fullfillmentVariables = listFullfillmentVariables.filter(
              (fv) =>
                fv.label === '' &&
                fv.nome_variabile === '' &&
                fv.istruzioni_estrazione === ''
            );

            const newDettagliNodi = Object.keys(editorValueArray).reduce(
              (acc, key) => {
                if (key !== 'fullfillment_variables') {
                  const nodeData = editorValueArray[key];
                  acc[key] = {
                    node_label: nodeData.node_label,
                    id: nodeData.id,
                    type: 'text',
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    position: nodeData.position,
                    intents: nodeData.intents || [],
                    fullfillment:
                      fullfillmentVariables.filter(
                        (fv) => fv.nodeId === nodeData.id
                      ) || [],
                  };
                }
                return acc;
              },
              {}
            );

            const newEdges = [];
            Object.keys(newDettagliNodi).forEach((key) => {
              const node = newDettagliNodi[key];
              if (node.intents) {
                node.intents.forEach((intent) => {
                  newEdges.push({
                    id: `${node.id}-${intent.nextNode}`,
                    source: node.id.toString(),
                    target: intent.nextNode.toString(),
                    animated: true,
                    type: 'bezier',
                    markerEnd: {
                      type: MarkerType.ArrowClosed,
                      width: 20,
                      height: 20,
                    },
                    data: {
                      trigger: intent.trigger,
                      output_intent: intent.output_intent,
                      type: 'action',
                      id: intent.id,
                      target: intent.nextNode,
                      source: node.id,
                    },
                  });
                });
              }
            });

            setEdges(newEdges);
          }

          // Dettagli nodi
          setDettagliNodi(
            JSON.parse(response.data.response.conversationalJSON)
          );
          setVersioniAgenteConversazionale(
            response.data.response.versionsConversational
          );

          if (closeModalCreateButton) {
            closeModalCreateButton.click();
          }
          toast.success(
            'Le modifiche della versione "' +
              response.data.response.name +
              '" sono state salvate'
          );
        }
      })
      .catch((err) => setError(err));
  };

  useEffect(() => {
    setDominioDelSito('');
    setLoading(true);

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        } else {
          setLoading(false);
          setEditorValue(response.data.response.conversationalJSON);
          try {
            const parsedJson = JSON.parse(
              response.data.response.conversationalJSON
            ); // Validazione del JSON
            setConversationalJson(parsedJson); // Aggiorna il JSON nello stato genitore
          } catch (e) {
            console.warn('Invalid JSON:', e.message); // Mostra un avviso in caso di errore
          }
          setError('');
        }
      })
      .catch((err) => setError(err));
  }, []);

  //   Monaco
  const [editorValue, setEditorValue] = useState(
    JSON.stringify(conversationalJson, null, 2) // Formatta il JSON con indentazione
  );

  // Aggiorna lo stato quando il JSON cambia
  useEffect(() => {
    setEditorValue(JSON.stringify(conversationalJson, null, 2)); // Formattazione con indentazione
  }, [conversationalJson]);

  // Gestore delle modifiche nell'editor
  const handleEditorChange = (value) => {
    setEditorValue(value);
    try {
      const parsedJson = JSON.parse(value); // Validazione del JSON
      setConversationalJson(parsedJson); // Aggiorna il JSON nello stato principale
    } catch (e) {
      console.warn('Invalid JSON:', e.message); // Mostra un avviso in caso di errore
    }
  };

  return (
    <div className="modal fade overlay " id="modalJsonEditor">
      <div className="modal-dialog modal-dialog-centered d-grid justify-content-center">
        <div className="modal-content" style={{ width: '1000px' }}>
          <div className="modal-header">
            <h5 className="modal-title">Versione JSON</h5>
          </div>
          <div className="modal-body">
            <div
              style={{
                border: '1px solid #ccc',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <Editor
                height="50vh"
                maxHeight="50vh"
                theme="vs-dark"
                value={JSON.stringify(dettagliNodi, null, 2)} // Formatta JSON
                onChange={(valore) => {
                  try {
                    setDettagliNodi(JSON.parse(valore)); // Parso il JSON modificato
                  } catch (error) {
                    console.error('JSON non valido:', error);
                  }
                }}
                defaultLanguage="json"
                options={{
                  minimap: { enabled: false }, // Nasconde la minimappa
                  formatOnPaste: true, // Formatta automaticamente
                  formatOnType: true, // Suggerimento formattazione
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              //   data-bs-dismiss="modal"
              onClick={AggiornaNodiConversazione}
            >
              Aggiorna
            </button>
            <button
              id="modalEditorJsonButton"
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalJsonEditor;
