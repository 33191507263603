import React, { useState, useEffect, useContext } from 'react';
import { PiShuffleFill as ShuffleIcon } from 'react-icons/pi';
import { Button } from 'react-bootstrap';
import { IoMdSettings as SettingsIcon } from 'react-icons/io';
import { FaUnlink as UnlinkIcon } from 'react-icons/fa';
import { StateContext } from '../../StateContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdDelete as TrashIcon } from 'react-icons/md';

const ModalVariables = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const { showModalVariables, setShowModalVariables } =
    useContext(StateContext);
  const [label, setLabel] = useState('');
  const [nomeVariabile, setNomeVariabile] = useState('');
  const [istruzioni, setIstruzioni] = useState('');
  const [variabileSelezionata, setVariabileSelezionata] = useState(null);
  const [variabiliSelezionate, setVariabiliSelezionate] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (selectedNode) {
      setVariabiliSelezionate(
        dettagliNodi?.[`nodo-${selectedNode?.id}`]?.node_variables || []
      );
    }
  }, [selectedNode]);

  const handleSalvaVariabile = () => {
    if (!label || !nomeVariabile || !istruzioni) {
      toast.error('Tutti i campi devono essere compilati.');
      return;
    }

    // Verifica se `fullfillment_variables` esiste prima di usare `.some()`
    const isDuplicate =
      dettagliNodi.fullfillment_variables &&
      dettagliNodi.fullfillment_variables.some(
        (variabile) => variabile.nome_variabile === nomeVariabile
      );

    if (isDuplicate) {
      toast.error('Il nome della variabile esiste già.');
      return;
    }

    const nuovoOggetto = {
      label: label,
      nome_variabile: nomeVariabile,
      istruzioni_estrazione: istruzioni,
    };

    if (variabileSelezionata) {
      const updatedVariables = dettagliNodi.fullfillment_variables.map(
        (variabile) =>
          variabile.nome_variabile === variabileSelezionata.nome_variabile
            ? nuovoOggetto
            : variabile
      );

      setDettagliNodi((prev) => ({
        ...prev,
        fullfillment_variables: updatedVariables,
      }));
    } else {
      setDettagliNodi((prev) => ({
        ...prev,
        fullfillment_variables: prev.fullfillment_variables
          ? [...prev.fullfillment_variables, nuovoOggetto]
          : [nuovoOggetto],
      }));
    }

    toast.success('Variabile salvata con successo!');

    // Reset dei campi
    setLabel('');
    setNomeVariabile('');
    setIstruzioni('');
    setVariabileSelezionata(null);
    setShowForm(!showForm);
  };

  const handleVariabileClick = (variabile) => {
    setLabel(variabile.label);
    setNomeVariabile(variabile.nome_variabile);
    setIstruzioni(variabile.istruzioni_estrazione);
    setVariabileSelezionata(variabile);
    setShowForm(true); // Show the form when a variable is selected
  };

  const handleAggiungiVariabili = () => {
    setVariabileSelezionata(null);
    setLabel('');
    setNomeVariabile('');
    setIstruzioni('');
    setShowForm(true); // Show the form when adding a variable
  };

  const handleVariabileCheck = (event, variabile) => {
    setVariabiliSelezionate((prev) => {
      if (event.target.checked) {
        return [...prev, variabile];
      } else {
        return prev.filter(
          (v) => v.nome_variabile !== variabile.nome_variabile
        );
      }
    });
  };

  const handleUtilizzaNelNodo = () => {
    setDettagliNodi((prev) => {
      const nodoKey = `nodo-${selectedNode.id}`;
      const nodeVariables = prev[nodoKey]?.node_variables || [];

      let updatedNodeVariables = [...nodeVariables];
      let removedVariables = []; // Variabili che sono state rimosse

      // Controlla se la variabile è presente nel nodo e gestisci le rimozioni
      nodeVariables.forEach((varNodo) => {
        const isSelected = variabiliSelezionate.some(
          (varSelezionata) =>
            varSelezionata.nome_variabile === varNodo.nome_variabile
        );

        if (!isSelected) {
          // Se la variabile non è selezionata, rimuovila
          removedVariables.push(varNodo);
          updatedNodeVariables = updatedNodeVariables.filter(
            (v) => v.nome_variabile !== varNodo.nome_variabile
          );
        }
      });

      // Aggiungi o aggiorna le variabili selezionate
      variabiliSelezionate.forEach((varSelezionata) => {
        const existingVar = updatedNodeVariables.find(
          (v) => v.nome_variabile === varSelezionata.nome_variabile
        );

        if (!existingVar) {
          updatedNodeVariables.push({
            ...varSelezionata,
            required: false,
            value: '',
            action: 'acquisisci',
          });
          toast.success(
            `Variabile "${varSelezionata.nome_variabile}" inserita nel nodo`
          );
        } else {
          existingVar.required = existingVar.required ?? false;
          existingVar.value = '';
          existingVar.action = existingVar.action ?? 'acquisisci';
          toast.info(
            `La variabile "${varSelezionata.nome_variabile}" è già stata inserita nel nodo`
          );
        }
      });

      // Mostra un feedback per le variabili rimosse
      if (removedVariables.length > 0) {
        const removedNames = removedVariables
          .map((v) => v.nome_variabile)
          .join(', ');
        toast.info(
          `Le seguenti variabili sono state rimosse dal nodo: ${removedNames}`
        );
      }

      setShowModalVariables(false);

      return {
        ...prev,
        [nodoKey]: {
          ...prev[nodoKey],
          node_variables: updatedNodeVariables,
        },
      };
    });
  };

  const handleRimuoviVariabile = (variabileSelezionata) => {
    if (!variabileSelezionata) return;

    // Lista dei nodi in cui la variabile è usata
    const nodiConVariabile = [];

    // Controllo se la variabile è usata in qualche nodo (escludendo viewport e fullfillment_variables)
    Object.entries(dettagliNodi).forEach(([nodoId, nodo]) => {
      if (nodoId !== 'viewport' && nodoId !== 'fullfillment_variables') {
        const variabilePresente =
          nodo.node_variables?.some(
            (variabile) =>
              variabile.nome_variabile === variabileSelezionata.nome_variabile
          ) ||
          nodo.widgets?.some(
            (widget) =>
              widget.nome_variabile === variabileSelezionata.nome_variabile
          );

        if (variabilePresente) {
          nodiConVariabile.push(nodo.node_label); // Salviamo l'ID del nodo
        }
      }
    });

    if (nodiConVariabile.length > 0) {
      toast.error(
        `Impossibile rimuovere la variabile perché è ancora in uso nei seguenti nodi: ${nodiConVariabile.join(
          ', '
        )}`
      );
      return;
    }

    // Se non è in uso, procedi con la rimozione
    const updatedVariables = dettagliNodi.fullfillment_variables.filter(
      (variabile) =>
        variabile.nome_variabile !== variabileSelezionata.nome_variabile
    );

    setDettagliNodi((prev) => ({
      ...prev,
      fullfillment_variables: updatedVariables,
    }));

    setLabel('');
    setNomeVariabile('');
    setIstruzioni('');
    setShowForm(false); // Nascondi il form dopo la rimozione

    toast.success(`La variabile è stata rimossa correttamente`);
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD') // Decompone caratteri accentati
      .replace(/[̀-ͯ]/g, '') // Rimuove segni diacritici
      .replace(/['"`!@#$%^&*()=+\[\]{};:\\|,.<>?/~]/g, '_') // Sostituisce simboli speciali con _
      .replace(/\s+/g, '_'); // Sostituisce spazi con _
  };

  const nomeVariabileChange = (e) => {
    setNomeVariabile(normalizeString(e.target.value));
  };

  const handleCloseModal = () => setShowModalVariables(false);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center my-2 p-0 position-absolute"
      style={
        {
          // backgroundColor: 'white',
          // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }
      }
    >
      <div
        className="w-50 d-flex justify-content-between"
        style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="modal-content bg-blue-body">
          <div
            className="modal-header bg-blue-header text-white p-3 py-2"
            data-bs-theme="dark"
          >
            <h5
              className="modal-title modal-title-editor-variabili"
              id="staticBackdropLabel"
            >
              <span className="d-flex align-items-center gap-2">
                <ShuffleIcon style={{ color: '#FFFFFF' }} />
                Editor Variabili del progetto
              </span>
            </h5>
            <button
              type="button"
              className="btn-close btn-close-editor-variabili"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModalVariables(false)}
            ></button>
          </div>

          <div className="d-flex p-2">
            {!showForm ? (
              <div className="modal-body bg-blue-body d-block">
                <p className="fw-bold">Variabili disponibili</p>
                <p className="mt-1 mb-3">Elenco variabili disponibili</p>
                <div id="elencoScelte" className="lista-variabili-globali">
                  {Array.isArray(dettagliNodi?.fullfillment_variables) &&
                    dettagliNodi.fullfillment_variables
                      .filter(
                        (variabile) =>
                          variabile.label !== '' &&
                          variabile.nome_variabile !== '' &&
                          variabile.istruzioni_estrazione !== ''
                      )
                      ?.map((variabile) => (
                        <div
                          className="scelta-variabile d-flex align-items-center justify-content-between"
                          key={variabile.nome_variabile}
                        >
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={variabiliSelezionate.some(
                                (v) =>
                                  v.nome_variabile === variabile.nome_variabile
                              )}
                              onChange={(e) =>
                                handleVariabileCheck(e, variabile)
                              }
                            />
                            <span
                              className="ms-2"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleVariabileClick(variabile)}
                            >
                              {variabile.label}
                            </span>
                          </div>
                          <div>
                            <SettingsIcon
                              className={`font-size-16 settings-icon me-1 ${
                                variabile === variabileSelezionata
                                  ? 'text-orange'
                                  : ''
                              }`}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleVariabileClick(variabile)}
                            />
                            <TrashIcon
                              className="font-size-16 settings-icon text-danger"
                              onClick={() => handleRimuoviVariabile(variabile)}
                            />
                          </div>
                        </div>
                      ))}
                </div>

                <div className="mt-3 d-flex gap-2">
                  <button
                    type="button"
                    className="btn bg-blue-header text-white"
                    onClick={handleAggiungiVariabili}
                  >
                    Aggiungi Variabile
                  </button>
                  <button
                    type="button"
                    className="btn bg-green-second text-white"
                    onClick={handleUtilizzaNelNodo}
                  >
                    Utilizza nel nodo
                  </button>
                </div>
              </div>
            ) : (
              <div className="modal-body bg-blue-body d-block">
                <label htmlFor="basic-url" className="form-label">
                  Etichetta variabile
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </div>

                <label htmlFor="basic-url" className="form-label">
                  Nome variabile
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    value={nomeVariabile}
                    onChange={nomeVariabileChange}
                  />
                </div>

                <label htmlFor="obiettivo-nodo" className="form-label mt-2">
                  Istruzioni di estrazione
                </label>

                <div className="input-group mb-3">
                  <textarea
                    id="obiettivo-nodo"
                    className="form-control"
                    value={istruzioni}
                    onChange={(e) => setIstruzioni(e.target.value)}
                  ></textarea>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  {/* {variabileSelezionata ? (
                    <span
                      type="button"
                      className="bg-transparent text-danger"
                      onClick={handleRimuoviVariabile}
                    >
                      Rimuovi variabile
                    </span>
                  ) : (
                    <div style={{ visibility: 'hidden' }}>
                      Rimuovi variabile
                    </div>
                  )} */}

                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => setShowForm(!showForm)}
                    >
                      Annulla
                    </button>

                    <button
                      type="button"
                      className="btn bg-blue-button text-white"
                      onClick={handleSalvaVariabile}
                    >
                      Salva variabile
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVariables;
