import React, { useContext, useState, useEffect } from 'react';
import { RxText as TestoLiberoIcon } from 'react-icons/rx';
import { MdLockOutline as RispostaChiusaIcon } from 'react-icons/md';
import { IoMdTime as TimePickerIcon } from 'react-icons/io';
import { FaRegCalendarAlt as DatePickerIcon } from 'react-icons/fa';
import RispostaChiusa from './inputUtenteWidgets/RispostaChiusa';
import TimePicker from './inputUtenteWidgets/TimePicker';
import { StateContext } from '../../StateContainer';
import ModalVariables from '../modal/ModalVariables';
import DatePicker from './inputUtenteWidgets/DatePicker';
import TestoLiberoWidget from './inputUtenteWidgets/TestoLiberoWidget';

const InputUtenteWidget = ({
  closeModal,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const {
    showModalVariables,
    setShowModalVariables,
    inputUtenteWidget,
    setInputUtenteWidget,
    setCurrentTab,
  } = useContext(StateContext);
  const handleOpenModal = () => setShowModalVariables(true);

  useEffect(() => {
    if (!selectedNode) return; // Evita di sovrascrivere lo stato

    const widget = dettagliNodi[`nodo-${selectedNode.id}`]?.widgets;
    if (widget && widget.length > 0) {
      const widgetInside = widget[0];
      if (widgetInside?.type === 'select' || widgetInside?.type === 'button') {
        setInputUtenteWidget('#rispostaChiusa');
      } else if (widgetInside?.type === 'date') {
        setInputUtenteWidget('#datePicker');
      } else {
        setInputUtenteWidget('#timePicker');
      }
    } else {
      setInputUtenteWidget('#testoLibero');
    }
  }, [selectedNode]);

  console.log(dettagliNodi);
  const modalTabMenu = [
    {
      id: 1,
      type: 'freetext',
      title: 'Testo Libero',
      section: '#testoLibero',
      icon: <TestoLiberoIcon />,
      typeWidget: '#testoLibero',
    },
    {
      id: 2,
      title: 'Risposta Chiusa',
      type: 'button',
      section: '#rispostaChiusa',
      icon: <RispostaChiusaIcon />,
      typeWidget: '#rispostaChiusa',
    },
    {
      id: 3,
      type: 'time',
      title: 'Time Picker',
      section: '#timePicker',
      icon: <TimePickerIcon />,
      typeWidget: '#timePicker',
    },
    {
      id: 4,
      type: 'date',
      title: 'Data Picker',
      section: '#datePicker',
      icon: <DatePickerIcon />,
      typeWidget: '#datePicker',
    },
  ];
  return (
    <>
      <div className="row my-3">
        <div className="widget-tabs col-2 position-relative">
          {modalTabMenu.map((component, groupIndex) => (
            <div className="">
              <button
                key={component.section}
                className={`btn widget-tab-button mb-2 ${
                  component.typeWidget === inputUtenteWidget
                    ? 'bg-verde text-white'
                    : ''
                }`}
                style={{ width: '500px' }}
                onClick={() => {
                  setInputUtenteWidget(component.typeWidget);

                  if (selectedNode) {
                    setDettagliNodi((prev) => {
                      const nodeKey = `nodo-${selectedNode.id}`;
                      const nodeData = prev[nodeKey] || {};
                      const widgets = nodeData.widgets || [];

                      const updatedWidgets =
                        widgets.length > 0
                          ? [{ ...widgets[0], type: component.type }]
                          : [{ type: component.type }];

                      return {
                        ...prev,
                        [nodeKey]: {
                          ...nodeData,
                          widgets: updatedWidgets,
                        },
                      };
                    });
                  }
                }}
              >
                <p className="font-size-18">{component.icon}</p>
                <p className="font-size-10">{component.title}</p>
              </button>
            </div>
          ))}
        </div>
        <div className="col-10">
          {inputUtenteWidget === '#rispostaChiusa' ? (
            <RispostaChiusa
              closeModal={closeModal}
              handleOpenModal={handleOpenModal}
              setCurrentTab={setCurrentTab}
              dettagliNodi={dettagliNodi}
              selectedNode={selectedNode}
              setDettagliNodi={setDettagliNodi}
            />
          ) : inputUtenteWidget === '#timePicker' ? (
            <TimePicker
              closeModal={closeModal}
              handleOpenModal={handleOpenModal}
              setCurrentTab={setCurrentTab}
              dettagliNodi={dettagliNodi}
              selectedNode={selectedNode}
              setDettagliNodi={setDettagliNodi}
            />
          ) : inputUtenteWidget === '#datePicker' ? (
            <DatePicker
              closeModal={closeModal}
              handleOpenModal={handleOpenModal}
              setCurrentTab={setCurrentTab}
              dettagliNodi={dettagliNodi}
              selectedNode={selectedNode}
              setDettagliNodi={setDettagliNodi}
            />
          ) : (
            <TestoLiberoWidget
              closeModal={closeModal}
              handleOpenModal={handleOpenModal}
              setCurrentTab={setCurrentTab}
              dettagliNodi={dettagliNodi}
              selectedNode={selectedNode}
              setDettagliNodi={setDettagliNodi}
            />
          )}
        </div>
        {showModalVariables && (
          <ModalVariables
            closeModal={closeModal}
            setCurrentTab={setCurrentTab}
            dettagliNodi={dettagliNodi}
            selectedNode={selectedNode}
            setDettagliNodi={setDettagliNodi}
          />
        )}
      </div>
    </>
  );
};

export default InputUtenteWidget;
