import { useContext } from 'react';
import { StateContext } from '../../StateContainer';
import ModalVariables from '../modal/ModalVariables';

const ApiEsterne = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
}) => {
  const { showModalVariables, setShowModalVariables } =
    useContext(StateContext);
  const nodeKey = `nodo-${selectedNode.id}`;
  const externalService = dettagliNodi[nodeKey]?.external_service || {
    type: '',
    api_url: '',
    service_description: '',
    method: '',
    keep_service_knowledge: false,
    parameters: [],
  };

  const handleChange = (key, value) => {
    setDettagliNodi((prev) => ({
      ...prev,
      [nodeKey]: {
        ...prev[nodeKey],
        external_service: {
          ...prev[nodeKey]?.external_service,
          [key]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (label, name, checked) => {
    setDettagliNodi((prev) => {
      const updatedParams = checked
        ? [
            ...(prev[nodeKey]?.external_service?.parameters || []),
            { label, nome_variabile: name, method: 'GET', required: false },
          ]
        : prev[nodeKey]?.external_service?.parameters?.filter(
            (param) => param.label !== label
          ) || [];

      return {
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          external_service: {
            ...prev[nodeKey]?.external_service,
            parameters: updatedParams,
          },
        },
      };
    });
  };

  const handleMethodChange = (label, method) => {
    setDettagliNodi((prev) => {
      const updatedParams = prev[nodeKey]?.external_service?.parameters.map(
        (param) => (param.label === label ? { ...param, method } : param)
      );
      return {
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          external_service: {
            ...prev[nodeKey]?.external_service,
            parameters: updatedParams,
          },
        },
      };
    });
  };

  console.log(dettagliNodi['nodo-1']);
  const handleOpenModal = () => setShowModalVariables(true);

  const handleRequiredChange = (label, required) => {
    setDettagliNodi((prev) => {
      const updatedParams = prev[nodeKey]?.external_service?.parameters.map(
        (param) => (param.label === label ? { ...param, required } : param)
      );
      return {
        ...prev,
        [nodeKey]: {
          ...prev[nodeKey],
          external_service: {
            ...prev[nodeKey]?.external_service,
            parameters: updatedParams,
          },
        },
      };
    });
  };

  return (
    <div className="row my-3" style={{ height: '530px' }}>
      <div className="widget-tabs col-4 position-relative">
        <label>Tipologia di servizio esterno</label>
        <select
          className="form-select mt-2"
          value={externalService.type}
          onChange={(e) => handleChange('type', e.target.value)}
        >
          <option value="">Seleziona</option>
          <option value="mysql">MySQL</option>
          <option value="invoke">Invoke</option>
        </select>

        <label className="form-label mt-2">Url API</label>
        <textarea
          className="form-control"
          value={externalService.api_url}
          onChange={(e) => handleChange('api_url', e.target.value)}
        />

        <label className="form-label mt-2">Descrizione del servizio</label>
        <textarea
          className="form-control"
          value={externalService.service_description}
          onChange={(e) => handleChange('service_description', e.target.value)}
        />

        <label>Metodo</label>
        <select
          className="form-select mt-2 mb-3"
          value={externalService.method}
          onChange={(e) => handleChange('method', e.target.value)}
        >
          <option value="">Seleziona</option>
          <option value="POST">POST</option>
          <option value="GET">GET</option>
          <option value="PUT">PUT</option>
        </select>

        <div className="d-flex align-items-center gap-2 justify-content-between">
          <label>Conserva conoscenza servizio</label>
          <div className="form-check form-switch py-0">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={externalService.keep_service_knowledge}
              onChange={(e) =>
                handleChange('keep_service_knowledge', e.target.checked)
              }
            />
          </div>
        </div>
      </div>
      <div className="col-8">
        <p className="text-center mt-3">Seleziona i parametri per il nodo</p>
        <div style={{ minHeight: '452px', overflowY: 'auto' }}>
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Nome</th>
                <th>Metodo</th>
                <th>Richiesto</th>
              </tr>
            </thead>
            <tbody>
              {dettagliNodi[nodeKey]?.node_variables?.map((variabile) => {
                const paramSaved =
                  (externalService.parameters || []).find(
                    (p) => p.label === variabile.label
                  ) || {};

                return (
                  <tr key={variabile.label}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleCheckboxChange(
                            variabile.label,
                            variabile.nome_variabile,
                            e.target.checked
                          )
                        }
                        checked={!!paramSaved.label}
                      />
                    </td>
                    <td>{variabile.label}</td>
                    <td>
                      <select
                        className="form-select w-50"
                        onChange={(e) =>
                          handleMethodChange(variabile.label, e.target.value)
                        }
                        disabled={!paramSaved.label}
                        value={paramSaved.method || 'GET'}
                      >
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                      </select>
                    </td>
                    <td>
                      <div className="form-check form-switch py-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          onChange={(e) =>
                            handleRequiredChange(
                              variabile.label,
                              e.target.checked
                            )
                          }
                          disabled={!paramSaved.label}
                          checked={paramSaved.required || false}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-auto">
        <button
          className="btn bg-green-second text-white"
          onClick={handleOpenModal}
        >
          Aggiungi parametri
        </button>
      </div>
      {showModalVariables && (
        <ModalVariables
          closeModal={closeModal}
          setCurrentTab={setCurrentTab}
          dettagliNodi={dettagliNodi}
          selectedNode={selectedNode}
          setDettagliNodi={setDettagliNodi}
        />
      )}
    </div>
  );
};

export default ApiEsterne;
