import React, { useState, memo, useContext } from 'react';
import { Position, Handle, useReactFlow } from '@xyflow/react';
import { StateContext } from '../StateContainer';
import { PiShuffleFill as ShuffleIcon } from 'react-icons/pi';
import { MdInput as InputIcon } from 'react-icons/md';
import { GoGear as GearIcon } from 'react-icons/go';

function ExecutionNode({ id, data }) {
  const { dettagliNodi, selectedNode, setSelectedNode } =
    useContext(StateContext);
  let nodo_corrente = dettagliNodi['nodo-' + id];
  console.log(dettagliNodi);
  console.log(nodo_corrente);
  return (
    <div className="card border rounded shadow bg-white position-relative card-start">
      <div className="card-header text-center bg-green-second text-white">
        {nodo_corrente && nodo_corrente?.node_label}
      </div>
      <div className="card-body p-0">
        <div className="sezione-nodo" style={{ fontSize: '10px' }}>
          {nodo_corrente?.output_assistant
            ? String(nodo_corrente.output_assistant)
            : ''}
        </div>

        {/* VARIABILI  */}
        {nodo_corrente &&
          nodo_corrente.node_variables &&
          nodo_corrente.node_variables.length > 0 && (
            <div className="sezione-nodo">
              <p
                style={{ fontSize: '12px' }}
                className="m-0 fw-bold d-flex align-items-center gap-2"
              >
                <ShuffleIcon /> VARIABILI
              </p>
              {nodo_corrente &&
                nodo_corrente.node_variables &&
                nodo_corrente?.node_variables.map((variabile) => {
                  return (
                    <div
                      className={`badge-variabile variabile-${variabile.action}`}
                      style={{ fontSize: '10px' }}
                    >
                      {variabile.nome_variabile}
                    </div>
                  );
                })}
            </div>
          )}

        {/* FORMATO INPUT  */}
        {nodo_corrente && nodo_corrente.widgets
          ? nodo_corrente?.widgets.map((widget) => {
              return (
                <div className="sezione-nodo">
                  <p
                    style={{ fontSize: '12px' }}
                    className="m-0 fw-bold d-flex align-items-center gap-2"
                  >
                    <InputIcon /> FORMATO INPUT
                  </p>
                  <div className={`badge-widget`} style={{ fontSize: '10px' }}>
                    {widget.type === 'button' || widget.type === 'select'
                      ? 'Risposta Chiusa'
                      : widget.type === 'time'
                      ? 'Time Picker'
                      : widget.type === 'date'
                      ? 'Data Picker'
                      : 'Testo libero'}
                  </div>
                </div>
              );
            })
          : ''}

        {/* TOOLS  */}

        {nodo_corrente && nodo_corrente.external_service ? (
          <div className="sezione-nodo">
            <p
              style={{ fontSize: '12px' }}
              className="m-0 fw-bold d-flex align-items-center gap-2"
            >
              <GearIcon /> TOOLS
            </p>
            <div style={{ fontSize: '10px' }}>
              {nodo_corrente.external_service &&
              nodo_corrente.external_service.type === 'invoke'
                ? 'Invoke'
                : 'MySQL'}
            </div>
          </div>
        ) : (
          ''
        )}
        <button
          className="btn button-modifica-nodo w-100 border-0"
          onClick={() => setSelectedNode(nodo_corrente)}
        >
          Modifica
        </button>
      </div>
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </div>
  );
}

export default memo(ExecutionNode);
