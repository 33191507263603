import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { PiShuffleFill as ShuffleIcon } from 'react-icons/pi';

const DatePicker = ({
  closeModal,
  setCurrentTab,
  dettagliNodi,
  selectedNode,
  setDettagliNodi,
  handleOpenModal,
}) => {
  const [selectedVariable, setSelectedVariable] = useState(null);

  useEffect(() => {
    if (selectedNode) {
      const nodeData =
        dettagliNodi[`nodo-${selectedNode.id}`]?.widgets?.[0] || {};
      setSelectedVariable(
        nodeData.variable_name
          ? { value: nodeData.variable_name, label: nodeData.variable_name }
          : null
      );
    }
  }, [selectedNode, dettagliNodi]);

  const handleChange = (selectedOption) => {
    setSelectedVariable(selectedOption);

    const updatedWidgets = [
      {
        type: 'date',
        variable_name: selectedOption?.value || null,
        variable_required: !!selectedOption,
      },
    ];

    const updatedNode = {
      ...dettagliNodi[`nodo-${selectedNode.id}`],
      widgets: updatedWidgets,
    };

    setDettagliNodi((prev) => ({
      ...prev,
      [`nodo-${selectedNode.id}`]: updatedNode,
    }));
  };

  const nodeVariables =
    dettagliNodi?.[`nodo-${selectedNode?.id}`]?.node_variables || [];

  const variable_options = nodeVariables.length
    ? [
        { value: null, label: 'Nessuna variabile' },
        ...nodeVariables.map((variable) => ({
          value: variable.label,
          label: variable.label,
        })),
      ]
    : [{ value: '', label: 'Nessuna variabile disponibile' }];

  return (
    <div className="row" style={{ height: '530px' }}>
      <div className="col-12" style={{ borderLeft: '1px black solid' }}>
        <div className="d-flex flex-column ps-1" style={{ height: '100%' }}>
          <div className="flex-grow-1">
            <label
              htmlFor="selezionaAzioneRapida"
              className="d-flex gap-2 align-items-center"
            >
              <ShuffleIcon className="fs-4" />
              Seleziona variabile
            </label>
            <p className="text-secondary mb-2">Collega variabile al widget</p>

            <Select
              options={variable_options}
              value={selectedVariable}
              onChange={handleChange}
              placeholder="Cerca..."
              isSearchable
            />
          </div>

          <div className="mt-auto d-flex align-items-center gap-2 justify-content-between">
            <button
              className="btn bg-green-second text-white px-4"
              onClick={handleOpenModal}
            >
              Crea nuova variabile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
